import { http } from '@/api';

// 获取榜单数据
export function getTop(params) {
  return http.get('/v1/stat/getinviterank', params);
}

// 获取用户邀请记录
export function getuserinvitationlog(params) {
  return http.get('/v1/user/getuserinvitationlog', params);
}

// 设置邀请人信息
export function setinvitecode(params) {
  return http.post('/v1/invite/setinvitecode', params);
}

// 获取历史活动列表
export function getactivitylist(params) {
  return http.get('/v1/activity/getactivitylist', params);
}

// 获取活动获奖名单
export function getactivityuserlist(params) {
  return http.get('/v1/activity/getactivityuserlist', params);
}

// 获取活动信息
export function getactivityinfo(params) {
  return http.get('/v1/activity/getactivityinfo', params);
}

// 提现
export function withdraw(params) {
  return http.get('/v1/withdraw/withdraw', params);
}

// 提现类型
export function withdrawways(params) {
  return http.get('/v1/withdraw/withdrawways', params);
}
// 活动提现列表
export function withdrawlist(params) {
  return http.get('/v1/withdraw/withdrawlist', params);
}
// 用户红包账户变更列表
export function withdrawchangelist(params) {
  return http.get('/v1/withdraw/withdrawchangelist', params);
}

// 提现余额
export function redremain(params) {
  return http.get('/v1/withdraw/redremain', params);
}

// 余额提现
export function remainwithdraw(params) {
  return http.post('/v1/withdraw/remainwithdraw', params);
}

// 中奖名单滚动通知
export function getawardrollnotice(params) {
  return http.get('/v1/notice/getawardrollnotice', params);
}

// 用户邀请奖励消息
export function getuserinvitenotice(params) {
  return http.get('/v1/notice/getuserinvitenotice', params);
}

// 4期获取榜单数据
export function getinviterank100(params) {
  return http.get('/v1/stat/getinviterank100', params);
}

// 5期获取榜单数据
export function getinvitebuycountrank(params) {
  return http.get('/v1/stat/getinvitebuycountrank', params);
}
// 获取能量榜单数据
export function getactivityenergyrank(params) {
  return http.get('/v1/activity/getactivityenergyrank', params);
}
// 获取能量记录
export function getuserenergylog(params) {
  return http.get('/v1/activity/getuserenergylog', params);
}

// 获取收藏值排行榜
export function collectrank(params) {
  return http.get('/v1/collect/collectrank', params);
}

// 获取用户的收藏值
export function getusercollectdata(params) {
  return http.get('/v1/collect/getusercollectdata', params);
}

// 获取中奖码
export function getuserwincode(params) {
  return http.get('/v1/user/getuserwincode', params);
}

// 
export function getuserinvitationlogshoudan(params) {
  return http.get('/v1/user/getuserinvitationlogshoudan', params);
}