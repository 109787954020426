<template>
  <div style="position: relative">
    <img
      class="containerBg"
      src="https://activity-resource.321mh.com/nft/sgsd/saleAwardBg.png"
      alt=""
      srcset=""
    />

    <div class="content">
      <div class="rules_wraper">
        <div class="rules_box">
          <div class="result" v-if="myCode.length">
            <!-- <div class="resultStatus" v-if="diffTime('2022-11-14 12:00:00') > 0">
              <img class="iconGift" src="../../imgs/icon/icon_gift_blue.png" alt="" srcset="" />
              等待开奖
            </div> -->

            <!-- 12点到16点等待开奖 -->
            <div
              class="resultStatus"
              v-if="diffTime('2022-11-14 12:00:00') < 0 && diffTime('2022-11-14 16:00:00') > 0"
            >
              <img class="iconGift" src="../../imgs/icon/icon_gift_blue.png" alt="" srcset="" />
              <!-- 抽奖中 -->
              等待开奖
            </div>

            <!-- 16:00点到16:10分点抽奖中 -->
            <div
              class="resultStatus"
              v-if="diffTime('2022-11-14 16:00:00') < 0 && diffTime('2022-11-14 16:10:00') > 0"
            >
              <img class="iconGift" src="../../imgs/icon/icon_gift_blue.png" alt="" srcset="" />
              抽奖中
            </div>

            <div class="result_left" v-else-if="diffTime('2022-11-14 16:10:00') < 0">
              <div><img :src="prizeArr.length ? happy : cry" alt="" /></div>
              <div v-if="prizeArr.length">
                <div>恭喜您中奖</div>
                <div class="gain">
                  获得
                  {{
                    prizeArr
                      .map(item => {
                        return item.award_des;
                      })
                      .join(',')
                  }}
                </div>
              </div>

              <div v-if="!prizeArr.length">
                <div>很遗憾，您未能中奖</div>
                <div class="gain">下次抽奖再试试手气吧！</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="gain">未获得抽奖资格</div>
          </div>
        </div>
      </div>

      <div class="myCodeWraper">
        <div class="myCodeBox">
          <div class="title">
            <div style="position: relative">
              <img src="../../imgs/icon/cjbgTitle.png" alt="" />
              <div class="titleText">
                我的抽签码 <span>{{ myCode.length }}</span> 份
              </div>
            </div>
          </div>
          <div class="code">
            <div
              class="code_item"
              v-for="(item, index) in myCode"
              :key="index"
              v-show="index < 6 || showMall"
            >
              {{ item.ticket_code }}
            </div>
            <div
              class="code_add"
              v-for="(item, index) in 3 - (myCode.length % 3)"
              :key="index"
            ></div>
          </div>
          <div
            class="shouAll"
            style="text-align: center"
            v-if="myCode.length > 6"
            @click="showMall = !showMall"
          >
            {{ showMall ? '收起' : '查看全部' }}
            <van-icon :name="showMall ? 'arrow-up' : 'arrow-down'" />
          </div>
        </div>
      </div>

      <div class="myCodeWraper">
        <div class="myCodeBox">
          <div class="title">
            <div style="position: relative">
              <img src="../../imgs/icon/cjbgTitle.png" alt="" />
              <div class="titleText">活动规则</div>
            </div>
          </div>

          <div class="rules">
            <div>
              1、11月14日12:00，平台将对持有「上古兽蛋」的用户进行快照，每持有1份「上古兽蛋」，将获得1张抽奖码，快照后，用户可以点击看漫App——看漫数藏——首页banner，获取自己对应数量的抽奖码，每张抽奖码概率抽中1个奖品；
            </div>
            <br />
            <div>
              2、11月14日16:00开奖，中奖用户需截图联系客服确定中奖信息，中得其他平台藏品的用户需在对应平台实名注册；
            </div>
            <br />
            <div>3、开奖后3个工作日内，平台将对收集到的信息陆续完成对应藏品空投。</div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { nftUtils } from '@/utils';
import { getuserwincode } from '@/service/invite';
import moment from 'moment';

export default {
  setup() {
    const state = reactive({
      isApp: nftUtils.isInApp(),
      myCode: [],
      showMall: false,
      prizeArr: [],
      server_time: null
    });

    onMounted(() => {
      getData();
    });

    const getData = () => {
      getuserwincode({}).then(res => {
        console.log(res);
        if (res.status == 0) {
          state.myCode = res.data;
          // state.myCode = [];
          state.prizeArr = [];
          state.server_time = res.server_time;
          state.myCode.forEach(item => {
            if (item.status == 1) {
              state.prizeArr.push(item);
            }
          });
        }
      });
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      // console.log(props.server_time);
      return moment(moment(time)).diff(moment(state.server_time), 'millisecond');
    };

    return {
      ...toRefs(state),
      diffTime,
      happy: require('../../imgs/icon/icon_happy_blue_24@2x.png'),
      cry: require('../../imgs/icon/icon_cry_blue_24@2x.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.appTop {
  padding-top: 70px !important;
}

.containerBg {
  width: 100%;
}

.content {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);

  .rules_wraper {
    padding: 4px;
    margin-bottom: 28px;
    width: 343px;
    background: #444dc3;
    font-size: 12px;

    .rules_box {
      border: 1px solid #58dedd;
      padding: 12px;

      .result {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .resultStatus {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
          line-height: 24px;
          padding: 10px 0;
          margin: 0 auto;
        }

        .iconGift {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
        .result_left {
          display: flex;
          align-items: flex-start;

          font-weight: 600;
          font-size: 16px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 7px;
          }
        }
      }
    }
    .gain {
      font-weight: 400;
      font-size: 10px;
    }
  }

  .myCodeWraper {
    background: #1f2576;
    border: 1px solid #444dc3;
    width: 343px;
    margin-bottom: 32px;
  }

  .rules {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
  .myCodeBox {
    padding: 12px;
    padding-top: 50px;
    position: relative;

    .shouAll {
      font-size: 14px;
      font-weight: 400;
    }

    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -8px;
      font-size: 0;

      img {
        width: 185px;
        height: 38px;
      }

      .titleText {
        font-size: 16px;
        white-space: nowrap;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
      }
      span {
        color: $primary;
      }
    }
    .code {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .code_item {
        width: 95px;
        height: 44px;
        background-image: url('../../imgs/icon/Icon_cjbg.png');
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: $primary;
        font-weight: 600;
        margin-bottom: 12px;
        // margin-right: 13px;
      }
      .code_add {
        width: 95px;
      }
    }
    .rule_title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 14px;
      span {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        margin: 0 4px;
      }
      img {
        width: 77px;
        height: 10px;
      }
    }
  }
}
</style>
